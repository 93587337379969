import LAYOUT from '@/layout/layout';

export default [
  {
    path: '/welcome',
    component: LAYOUT.base,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'welcome',
        component: () =>
          import(
            /* webpackChunkName: '' */ '@/modules/Welcome/views/Welcome.vue'
          ),
      },
    ],
  },
];
