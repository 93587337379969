import AxiosInstance from '@/helpers/AxiosInstance';
import axiosErrorHandler from '@/helpers/axiosErrorHandler';
import { encrypt } from '@/helpers/crypt';
import { formatAmount } from '@/helpers/currency';

const state = {
  orders: {
    orders: null,
    orders_filter: null,
    orders_header_map: {
      clientFirstName: {
        text: 'First Name',
        value: 'clientFirstName',
        width: '180px',
        type: 'clientFirstName',
        sortable: false,
      },
      clientLastName: {
        text: 'Last Name',
        value: 'clientLastName',
        width: '180px',
        type: 'clientLastName',
        sortable: false,
      },
      orderDate: {
        text: 'Operation date',
        value: 'orderDate',
        width: '136px',
        type: 'orderDate',
        sortable: false,
      },
      amount: {
        text: 'Amount',
        value: 'amount',
        width: '130px',
        type: 'amount',
        sortable: false,
        valueParser: formatAmount,
      },
      currency: {
        text: 'Currency',
        value: 'currency',
        width: '92px',
        type: 'currency',
        sortable: false,
      },
      order_type: {
        text: 'Order type',
        value: 'orderType',
        width: '160px',
        type: 'order_type',
        sortable: false,
      },
      orderStatus: {
        text: 'Order status',
        value: 'orderStatus',
        width: '160px',
        type: 'orderStatus',
        sortable: false,
      },
      referenceNumber: {
        text: 'Reference Number',
        value: 'referenceNumber',
        width: '173px',
        type: 'referenceNumber',
        sortable: false,
      },
      details: {
        text: 'Details',
        value: 'details',
        width: '160px',
        type: 'details',
        sortable: false,
      },
    },
  },
};

const mutations = {
  SET_ORDER_FILTER(state, payload) {
    state.orders.orders_filter = payload.properties;
  },
  SET_ORDERS_LIST(state, payload) {
    state.orders.orders = payload;
  },
  CLEAR_ORDERS_STATE(state) {
    state.orders.orders_filter = null;
    state.orders.orders = null;
  },
};

const actions = {
  async getOrdersFilter({ commit }, params = {}) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: '707xp78k',
          method: 'get',
          params: params,
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200) {
        commit('SET_ORDER_FILTER', data);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },
  async getOrders({ commit }, params = {}) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: '707xp78k',
          method: 'post',
          params: params,
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200) {
        commit('SET_ORDERS_LIST', data);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },
};

const getters = {
  orders: (state) => state.orders.orders,
  ordersFilter: (state) => state.orders.orders_filter,
  ordersHeaderMap: (state) => state.orders.orders_header_map,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
