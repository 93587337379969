import LAYOUT from '@/layout/layout';

export default [
  {
    path: '/cards',
    component: LAYOUT.base,
    meta: { auth: true, permissions: 'cards-list-view' },
    children: [
      {
        path: '',
        name: 'cards',
        component: () =>
          import(/* webpackChunkName: '' */ '@/modules/Cards/views/Cards.vue'),
      },
    ],
  },
];
