<template>
  <div
    class="app-wrapper"
    :class="{
      isMobile: toggleNav,
      isActiveFilter: isMobileOverflow,
      isOverflow: isOverflow,
      isOverflow: isOverflow,
    }"
  >
    <v-toolbar dark flat color="black" height="72" class="header">
      <v-btn
        class="burger pa-0"
        icon
        color="white"
        @click="toggleNav = !toggleNav"
      >
        <v-icon color="white" size="24" v-if="toggleNav">mdi-close</v-icon>
        <v-icon color="white" size="24" v-else>mdi-menu</v-icon>
      </v-btn>

      <div class="header-logo">
        <img
          class="mr-6"
          :src="require('@/assets/logo.svg')"
          height="24"
          loading="eager"
          alt="logo"
        />
      </div>

      {{/* MENU */}}
      <v-toolbar-items class="header-menu" :class="{ active: toggleNav }">
        <div v-for="(item, index) in menuItems" :key="index">
          <v-btn
            v-if="
              isAdmin ||
              (userAuthPermissions && userAuthPermissions[item.permissions] > 0)
            "
            dark
            text
            :ripple="false"
            :to="{ path: item.url }"
            :disabled="item.disable"
            active-class="active"
            :exact="item.exact"
            @click="toggleNav = false"
            >{{ item.name }}
            <v-chip
              class="ma-2"
              label
              small
              color="primary"
              v-if="item.disable"
            >
              Soon
            </v-chip>
          </v-btn>
        </div>
      </v-toolbar-items>
      {{/* END MENU */}}

      <v-spacer></v-spacer>

      <v-toolbar-items class="header-navmenu">
        <v-menu
          content-class="menu_btn__user_menu"
          open-on-hover
          offset-y
          bottom
          min-width="341"
          absolute
          dark
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              :ripple="false"
              elevation="0"
              text
              class="menu_btn__user_btn"
            >
              <!-- <v-avatar color="white" size="48">
                <img
                  :src="userAvatar"
                  :alt="avatar_name"
                  v-if="userAvatar !== null"
                />
                <span class="black--text text-h6" v-else>{{
                  avatar_name
                }}</span>
              </v-avatar> -->

              <v-icon
                :color="attrs['aria-expanded'] === 'true' ? 'primary' : 'white'"
                class="ml-2"
                >mdi-menu-down</v-icon
              >
            </v-btn>
          </template>
          <v-list color="black">
            <v-subheader class="text-body-2 font-weight-bold white--text">
              {{ userFirstName + ' ' + userLastName }}
            </v-subheader>
            <template v-if="listSpaces && listSpaces.length">
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <div class="body3 font-weight-medium color-grey-1 pl-0 mb-2">
                    {{ $t('header.menu_user.active_space') }}
                  </div>
                  <div class="sub2 font-weight-bold white--text pl-0 mb-2">
                    {{ activeSpace }}
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="spaces-list">
                  <div class="body3 font-weight-medium color-grey-1 pl-0 mb-2">
                    {{ $t('header.menu_user.all_spaces') }}
                  </div>
                  <div
                    class="spaces-list__item sub2 font-weight-bold pl-0"
                    :class="index + 1 < listSpaces.length ? 'mb-3' : 'mb-0'"
                    v-for="(item, index) in listSpaces"
                    :key="index"
                    @click="changeSpace(item)"
                  >
                    {{ item }}
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>

            <v-divider></v-divider>

            <v-list-item link to="/settings/profile/">
              <v-list-item-icon>
                <v-icon size="16">mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="body1"
                v-text="$t('header.menu_user.profile_settings')"
              ></v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item link @click="$store.dispatch('logout')">
              <v-list-item-icon>
                <v-icon size="16">$vuetify.icons.iconlogout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="$t('header.menu_user.log_out')"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import AxiosInstance from '@/helpers/AxiosInstance';
import { vueToast } from '@/helpers/utils';
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'AppLayout',

  async created() {
    // await this.$store.dispatch("axiosGetUserGeneralInfo");
    // await this.$store.dispatch("axiosGetAvatar");
    this.SET_CURRENT_WHITE_SPACE();
    this.SET_ALL_WHITE_SPACES();
    this.SET_IS_ADMIN();
    if (!this.isAdmin && !this.authPermissions) {
      await this.getPermissions();
    }
  },

  computed: {
    ...mapGetters([
      'isMobileOverflow',
      'isCardDetail',
      'isOverflow',
      'activeSpace',
      'listSpaces',
      'userAuthPermissions',
      'isAdmin',
    ]),
    userFirstName() {
      return this.$cookies.get('first_name');
    },
    userLastName() {
      return this.$cookies.get('last_name');
    },
  },

  data: () => ({
    toggleNav: false,
    menuItems: [
      {
        name: 'Clients',
        url: '/clients',
        disable: false,
        permissions: 'clients-list-view',
      },
      {
        name: 'Cards',
        url: '/cards',
        disable: false,
        permissions: 'cards-list-view',
      },
      {
        name: 'Operations',
        url: '/operations',
        disable: false,
        permissions: 'operations-list-view',
      },
      {
        name: 'Orders',
        url: '/orders',
        disable: false,
        permissions: 'orders-list-view',
      },
      {
        name: 'Master account',
        url: '/master',
        disable: false,
        exact: true,
        permissions: 'master-account-view',
      },
      { name: 'Reports', url: '/reports', disable: true },
    ],
  }),
  methods: {
    ...mapActions(['getPermissions']),
    ...mapMutations([
      'SET_MOBILE_OVERFLOW',
      'UPDATE_WHITE_SPACE',
      'SET_CURRENT_WHITE_SPACE',
      'SET_ALL_WHITE_SPACES',
      'SET_IS_ADMIN',
    ]),
    toggleMobileFilter() {
      this.SET_MOBILE_OVERFLOW();
    },
    async changeSpace(space) {
      try {
        const { status } = await AxiosInstance.post('/api/users/set-wl', {
          wl: space,
        });
        if (status === 200) {
          localStorage.setItem('active_spaces', space);
          this.SET_CURRENT_WHITE_SPACE();
          this.UPDATE_WHITE_SPACE();
        }
      } catch (e) {
        vueToast(e.response.data.message, 'error');
      }
    },
  },
};
</script>

<style lang="scss">
.app-wrapper {
  min-height: 100vh;
  background: #fff;
}
.v-main {
  min-height: calc(100vh - 72px);
}

.header {
  position: relative;
  z-index: 8;
  @include max-media(1100px) {
    .v-toolbar__content {
      justify-content: space-between;
    }
    .spacer {
      display: none !important;
    }
    .header-logo {
      img {
        margin-right: 0px !important;
      }
    }
    .header-menu {
      position: absolute;
      left: 0;
      top: 71px;
      height: calc(100vh - 71px);
      background: var(--v-black-base);
      z-index: 1;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.3s;
      transform: translateX(-100%);
      &.active {
        transform: translateX(0);
      }
    }
  }
  @include max-media($xs-breakpoint) {
    height: 44px !important;
    .v-toolbar__content {
      height: 44px !important;
    }
    .header-menu {
      top: 43px;
      height: calc(100vh - 43px);
    }
  }
}

.burger {
  display: none;
  @include max-media(1100px) {
    display: block;
  }
}
//Header logo
.header-logo {
  line-height: 0;
}
//header Menu
.header-menu {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-btn.v-size--default {
    text-transform: inherit;
    font-size: 16px;
    padding: 0 24px;
    font-weight: 500 !important;

    .v-chip {
      padding: 2px 8px !important;
      font-weight: 700 !important;
      font-size: 10px !important;
      line-height: 1.6 !important;
      height: 20px !important;
    }

    &[aria-expanded='true'] {
      color: var(--v-primary-base);
    }
    &:hover,
    &:active,
    &:focus,
    &.v-btn--active {
      color: var(--v-primary-base);

      &[aria-expanded='true'] {
        i {
          transform: rotate(180deg);
          color: var(--v-primary-base);
        }
      }

      &:before {
        content: none;
      }
    }
  }
  .v-btn--disabled {
    color: #fff !important;
  }
}
//end header Menu

//nav Menu
.header-navmenu {
  .theme--dark.v-btn--active:hover::before,
  .theme--dark.v-btn--active::before {
    opacity: 0;
  }
}
.menu_btn {
  &__settings {
    &.v-btn--active {
      color: var(--v-primary-base) !important;
    }
  }

  &__user {
    &_btn {
      padding-right: 0 !important;
      &:focus::before {
        opacity: 0 !important;
      }
    }

    &_menu {
      border: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      box-shadow: none;
      right: 0 !important;
      top: 72px !important;
      left: unset !important;

      @include max-media($xs-breakpoint) {
        top: 44px !important;
      }

      .v-list {
        padding-top: 0;

        .v-list-item,
        .v-subheader {
          padding-left: 32px;
          padding-right: 32px;
        }
      }

      .v-list-item {
        &:before {
          opacity: 0;
        }
        &:hover {
          .v-list-item__title,
          .v-icon {
            color: var(--v-primary-base) !important;
          }
        }
        .v-list-item__icon:first-child {
          margin-right: 10px;
        }
      }

      .v-divider.theme--dark {
        border-color: var(--v-grayDark-base);
        opacity: 0.4;
      }
    }
  }
}

.isMobile {
  @include max-media(1100px) {
    overflow: hidden !important;
    height: 100vh !important;
  }
}
.isActiveFilter {
  @include max-media($md-breakpoint) {
    overflow: hidden !important;
    height: 100vh !important;
  }
}
// .isCardDetail {
//   @include max-media($md-breakpoint) {
//     overflow: hidden !important;
//   }
// }

.isOverflow {
  overflow: hidden !important;
  height: 100vh !important;
}

.spaces-list {
  &__item {
    cursor: pointer;
    transition: all 0.3s;
    color: $white;
    &:hover {
      color: $accent;
    }
  }
}
</style>
