import merge from 'lodash/merge';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { extra_modules_list } from '../modules/custom_modules_list';

Vue.use(VueI18n);

// array of all languages
const langs = ['en'];

// default/current language
const config_lang_code = 'en';

let lang_file = {};
for (const lang of langs) {
  const lang_obj = require(`./translations/${lang}.json`);
  lang_file = merge(lang_file, lang_obj);
}

// Add lang files from modules
const connectModuleLangs = (module) => {
  for (const lang of langs) {
    try {
      const lang_obj = require(`@/modules/${module.component_name}/lang/${lang}.json`);

      // lang_file = _.merge(lang_file, lang_obj);
      lang_file = merge(lang_file, lang_obj);
    } catch (e) {
      console.log('Not found module lang file', module.component_name);
    }
  }
};
extra_modules_list
  .filter((module) => module && module.active && module.component_name)
  .forEach(connectModuleLangs);

const i18n = new VueI18n({
  locale: config_lang_code,
  fallbackLocale: 'en',
  messages: lang_file,
});

console.log(lang_file);
// for translations by key
VueI18n.prototype.$tr = function (item, attribute) {
  if (!item) return '';

  return item[`${attribute}_${i18n.locale}`] ?? '';
};

export default i18n;
