const currencySymbolsByCode = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

export const formatAmount = (
  amount,
  {
    round = 'default',
    withZeros = true,
    separateThousands = true,
    decimalPlacesNumber = 2,
    currencyCode = null,
    showCurrencySymbol = false,
  } = {
    round: 'default',
    withZeros: true,
    separateThousands: true,
    decimalPlacesNumber: 2,
    currencyCode: null,
    showCurrencySymbol: false,
  },
) => {
  let result = +amount || 0;

  const accuracy = Math.pow(10, decimalPlacesNumber);
  if (round === 'up') {
    result = Math.ceil(+result * accuracy) / accuracy;
  } else if (round === 'down') {
    result = Math.floor(+result * accuracy) / accuracy;
  } else {
    result = Math.round(+result * accuracy) / accuracy;
  }

  if (withZeros) {
    result = result.toFixed(decimalPlacesNumber);
  } else {
    result = result.toString();
  }

  if (separateThousands) {
    let [int, dec] = `${result}`.split('.');
    result = int.length > 3 ? int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : int;

    if (dec) {
      result += `.${dec}`;
    }
  }

  if (
    showCurrencySymbol &&
    currencyCode &&
    currencySymbolsByCode[currencyCode]
  ) {
    result = currencySymbolsByCode[currencyCode] + ' ' + result;
  }

  return result;
};
