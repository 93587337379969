import LAYOUT from '@/layout/layout';

export default [
  {
    path: '/orders',
    component: LAYOUT.base,
    meta: { auth: true, permissions: 'orders-list-view' },
    children: [
      {
        path: '',
        name: 'orders',
        component: () =>
          import(
            /* webpackChunkName: '' */ '@/modules/Orders/views/Orders.vue'
          ),
      },
    ],
  },
];
