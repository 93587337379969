<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 7.50033L14.1667 4.16699V6.66699H8.33333V8.33366H14.1667V10.8337L17.5 7.50033ZM5.83333 9.16699L2.5 12.5003L5.83333 15.8337V13.3337H11.6667V11.667H5.83333V9.16699Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconSwapHorizontal',
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
