import Vue from 'vue';

export function vueToast(txt, type = 'error') {
  Vue.$toast.open({
    message: txt,
    type,
    position: 'bottom',
  });
}

export function transformDate(value) {
  const arr = value.split('/');
  const el = arr.shift();
  arr.splice(1, 0, el);
  return arr.join('/');
}

export function isExchangeAvailable() {
  const hour = Vue.prototype
    .$moment(new Date())
    .tz('Europe/London')
    .format('HH');
  return +hour < 16 && +hour >= 9;
}

export function debounce(func, delay) {
  let timerId;

  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
      timerId = null;
    }, delay);
  };
}

export const downloadFile = (blob, fileName) => {
  try {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Download file error: ', e);
  }
};
