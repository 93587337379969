import AxiosInstance from '@/helpers/AxiosInstance';
import axiosErrorHandler from '@/helpers/axiosErrorHandler';
import { encrypt } from '@/helpers/crypt';

const state = {
  master: {
    master_accounts_total_amount: null,
    master_accounts: null,
    related_accounts: null,
    sellCurrencies: [],
    buyCurrencies: [],
  },
};

const mutations = {
  SET_MASTER_ACCOUNTS_TOTAL_AMOUNT(state, payload) {
    state.master.master_accounts_total_amount = payload;
  },
  SET_MASTER_ACCOUNTS(state, payload) {
    state.master.master_accounts = payload;
  },
  SET_RELATED_ACCOUNTS(state, payload) {
    state.master.related_accounts = payload;
  },
  CLEAR_MASTER_STATE(state) {
    state.master.master_accounts = null;
  },
  SET_SELL_CURRENCIES(state, payload) {
    state.master.sellCurrencies = payload;
  },
  SET_BUY_CURRENCIES(state, payload) {
    state.master.buyCurrencies = payload;
  },
};

const actions = {
  async getMasterAccountTotalAmount(
    { commit },
    { currencyCode = 'EUR' } = { currencyCode: 'EUR' },
  ) {
    try {
      const { status, data } = await AxiosInstance.get(
        '/api/request/ma/total-value',
        { params: { currencyCode } },
      );
      if (status === 200) {
        commit('SET_MASTER_ACCOUNTS_TOTAL_AMOUNT', data);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },
  async getMasterAccountsList({ commit }) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: 'qmisu2ke',
          method: 'get',
          params: {},
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status == 200) {
        commit('SET_MASTER_ACCOUNTS', data);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  async getRelatedAccountsList({ commit }) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: '54d4d4d4',
          method: 'get',
          params: {},
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status == 200) {
        commit('SET_RELATED_ACCOUNTS', data);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  async sendAccInfoByEmail(email) {
    try {
      await AxiosInstance.post('/api/account/send-details', { email });
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  /**
   * @name fetchExchangeCurrencies
   * @description get buy and sell currencies
   * @param commit (SET_SELL_CURRENCIES) - set sell currencies
   * @param commit (SET_BUY_CURRENCIES) - set buy currencies
   */

  async fetchExchangeCurrencies({ commit }) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: 'e5tfd92d',
          method: 'get',
          params: {},
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200 && data.sellCurrencies && data.buyCurrencies) {
        commit('SET_SELL_CURRENCIES', data.sellCurrencies);
        commit('SET_BUY_CURRENCIES', data.buyCurrencies);
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  /**
   * @name fetchExchangeRate
   * @description get exchange rate
   */

  // eslint-disable-next-line consistent-return
  async fetchExchangeRate(_, params) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: 'vr2nf7ow',
          method: 'get',
          params: params,
        }),
      );
      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200) {
        return data;
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  /**
   * @name exchangeConvert
   * @description performs currency convert between client accounts
   */

  // eslint-disable-next-line consistent-return
  async exchangeConvert({ dispatch }, params) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: 'ed4gbxol',
          method: 'post',
          params: params,
        }),
      );

      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200 && data) {
        dispatch('getMasterAccountsList');
        dispatch('fetchExchangeCurrencies');
        return { data, status };
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },

  /**
   * @name setOrderOfAccount
   * @description set order of account
   */

  // eslint-disable-next-line consistent-return
  async setOrderOfAccount({ dispatch }, params) {
    try {
      const crypto = await encrypt(
        JSON.stringify({
          uri: 'lsyyn9ij',
          method: 'post',
          params: params,
        }),
      );

      const { status, data } = await AxiosInstance.post('/api/request', {
        data: crypto,
      });
      if (status === 200) {
        dispatch('getMasterAccountsList');
        dispatch('fetchExchangeCurrencies');
        return { data, status };
      }
    } catch (e) {
      axiosErrorHandler(e);
    }
  },
};

const getters = {
  masterAccountsTotalAmount: (state) =>
    state.master.master_accounts_total_amount,
  masterAccounts: (state) => state.master.master_accounts,
  relatedAccounts: (state) => state.master.related_accounts,
  sellCurrencies: (state) => state.master.sellCurrencies,
  buyCurrencies: (state) => state.master.buyCurrencies,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
