<template>
  <div class="sidebar">
    <div class="pa-5 pa-md-8 h5">{{ $t('settings.settings_title') }}</div>
    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-divider></v-divider>
      <div class="py-2 px-4 py-md-4 px-md-8">
        <router-link
          to="/settings/profile/"
          class="sub2"
          :class="{ active: activeLink('/settings/profile/') }"
          >{{ $t('settings.nav.profile_settings') }}</router-link
        >
      </div>
      <v-divider></v-divider>
      <template v-if="isAdmin">
        <div class="sub2 pt-4 pb-2 px-8">
          {{ $t('settings.nav.user_managements') }}
        </div>
        <div class="py-2 px-8">
          <router-link
            to="/settings/users/"
            class="body2"
            :class="{ active: activeLink('/settings/users/') }"
            >{{ $t('settings.nav.users') }}</router-link
          >
        </div>
        <div class="py-2 px-8">
          <router-link
            to="/settings/teams/"
            class="body2"
            :class="{
              active: activeLink('/settings/teams/'),
            }"
            >{{ $t('settings.nav.teams') }}</router-link
          >
        </div>
        <v-divider></v-divider>
      </template>
      <template v-if="!isAdmin && isTeamId">
        <div class="py-2 px-4 py-md-4 px-md-8">
          <router-link
            to="/settings/team-info/"
            class="sub2"
            :class="{ active: activeLink('/settings/team-info/') }"
            >{{ $t('settings.nav.team_budget') }}</router-link
          >
        </div>
      </template>
    </template>
    <template v-else>
      <div class="px-5 mb-0 mb-sm-5">
        <v-select
          :items="
            isAdmin
              ? mobile_nav_is_admin
              : isTeamId
              ? mobile_nav_user_team
              : mobile_nav_user_without_team
          "
          item-text="text"
          item-value="url"
          v-model="current_page"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          hide-details="auto"
          dense
          height="48"
          @change="changePage($event)"
        ></v-select>
      </div>
    </template>
    <!-- <div v-for="(item, index) in nav" :key="index">
      <div class="sub2 pt-4 pb-2 px-8" v-if="item.title">{{ item.title }}</div>
      <div class="py-4 px-8" v-for="(nav, i) in item.items" :key="i">
        <router-link :to="nav.url" :class="nav.class">{{
          nav.text
        }}</router-link>
      </div>
      <v-divider></v-divider>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Sidebar',

  created() {
    this.watchUrl();
  },

  watch: {
    $route() {
      this.watchUrl();
    },
  },

  computed: {
    ...mapGetters(['isAdmin']),
  },

  data() {
    return {
      isTeamId: localStorage.getItem('team_id'),
      current_page: '/settings/profile/',
      nav: [
        {
          items: [
            {
              text: 'Profile settings',
              url: '/settings/profile/',
              class: 'sub2',
            },
          ],
        },
        {
          title: 'User managements',
          items: [
            {
              text: 'Users',
              url: '/settings/users/',
              class: 'body2',
            },
            {
              text: 'Teams',
              url: '/settings/teams/',
              class: 'body2',
            },
          ],
        },
      ],

      mobile_nav_is_admin: [
        {
          text: 'Profile settings',
          url: '/settings/profile/',
        },
        {
          text: 'User managements / Users',
          url: '/settings/users/',
        },
        {
          text: 'User managements / Teams',
          url: '/settings/teams/',
        },
      ],
      mobile_nav_user_team: [
        {
          text: 'Profile settings',
          url: '/settings/profile/',
        },
        {
          text: 'Team budget',
          url: '/settings/team-info/',
        },
      ],

      mobile_nav_user_without_team: [
        {
          text: 'Profile settings',
          url: '/settings/profile/',
        },
      ],
    };
  },

  methods: {
    changePage(url) {
      this.$router.push(url);
    },
    activeLink(str) {
      return this.current_page.indexOf(str) !== -1 ? true : false;
    },
    watchUrl() {
      if (this.$route.fullPath.includes('/settings/teams')) {
        this.current_page = '/settings/teams/';
      } else {
        this.current_page = this.$route.fullPath;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  a {
    color: $black !important;
    &.active,
    &:hover {
      color: $accent !important;
    }
  }
}
</style>
