import VueScroll from 'vuescroll';

export default {
  install: (Vue) => {
    Vue.use(VueScroll, {
      ops: {
        rail: { size: '2px' },
        bar: {
          wheelScrollDuration: 1400,
          background: '#FE8A01',
          keepShow: true,
          onlyShowBarOnScroll: false,
          size: '2px',
          specifyBorderRadius: '0',
        },
      },
    });
  },
};
