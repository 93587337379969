import { router } from '@/main.js';

const axiosErrorHandler = function (error) {
  const { status } = error?.response;
  if (status && status === 403) {
    router.push({ name: 'login' });
  }
};

export default axiosErrorHandler;
