import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@mdi/font/css/materialdesignicons.css';

import IconEye from '@/components/icons/IconEye.vue';
import IconLogout from '@/components/icons/IconLogout.vue';
import IconClose from '@/components/icons/IconClose';
import IconTable from '@/components/icons/IconTable';
import IconTransferMoney from '@/components/icons/IconTransferMoney';
import IconSwapHorizontal from '@/components/icons/IconSwapHorizontal';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#fe8a01',
        orangeLight1: '#fff0de',
        secondary: '#050038',
        gray: '#e5e5e5',
        gray2: '#f8f8f8',
        gray3: '#f3f3f3',
        gray5: '#808080',
        grayDark: '#afafaf',
        black: '#000',
        purple: '#45109f',
        orangeLight: '#fff0de',
        orangeLight2: '#ffe0bc',
        green: '#38c357',
        success: '#38c357',
        red: '#ef1d26',
        white: '#fff',

        gray1: '#AFAFAF',
        gray4: '#F8F8F8',
        black2: '#292931',
        orangeLight3: '#F0C591',

        status_active: '#38c357',
        status_not_active: '#afafaf',
        status_closing: '#45109f',
        status_closed: '#000',
        status_soft_block: '#fe8a01',
        status_hard_block: '#ef1d26',
      },
      dark: { primary: '#fff' },
    },
  },
  customVariables: ['../scss/variables.scss'],
  icons: {
    iconfont: 'mdi',
    values: {
      iconeye: { component: IconEye },
      iconlogout: { component: IconLogout },
      iconclose: { component: IconClose },
      IconTable: { component: IconTable },
      IconTransferMoney: { component: IconTransferMoney },
      IconSwapHorizontal: { component: IconSwapHorizontal },
    },
  },
});
