import i18n from '@/lang/i18n';

export const clientStatusTranslationKeyByStatus = {
  NotActive: 'clients.detail.status.not_active',
  Active: 'clients.detail.status.active',
  UnderReview: 'clients.detail.status.under_review',
  Closed: 'clients.detail.status.closed',
  ClosingAcc: 'clients.detail.status.closing_acc',
  HardBlocked: 'clients.detail.status.hard_blocked',
  SoftBlocked: 'clients.detail.status.soft_blocked',
  Rejected: 'clients.detail.status.rejected',
  RiskAssessmentRequired: 'clients.detail.status.risk_assessment_required',
};

export const getClientStatus = (status) =>
  i18n.t(clientStatusTranslationKeyByStatus[status]) || status || '';

export const clientKycStatusTranslationKeyMap = {
  NotStarted: 'clients.detail.kyc_status_enums.not_started',
  Pending: 'clients.detail.kyc_status_enums.pending',
  Passed: 'clients.detail.kyc_status_enums.passed',
  Failed: 'clients.detail.kyc_status_enums.failed',
};

export const getClientKycStatus = (status) =>
  i18n.t(clientKycStatusTranslationKeyMap[status]) || status || 'N/A';

export const resendClientEntityData = {
  kyc_form: {
    route: '/api/request/clients/kyc/resend-link',
    successMessage: 'clients.detail.kyc_resend_form_success',
    submitMessage: 'clients.detail.kyc_resend_warning_message',
  },
};
