<template>
  <v-app class="app-main">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        console.log('PLEASE UPDATE FOR NEW VERSION');
        this.update();
      });
    }
  },

  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto;
}
</style>
