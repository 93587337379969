import { Config } from '@/constants/config';
import axios from 'axios';
import Vue from 'vue';
import { decryptRoute } from '@/helpers/crypt';

const AxiosInstance = axios.create({
  baseURL: Config.VUE_APP_BASEURL,
  withCredentials: true,
});

AxiosInstance.interceptors.request.use(function (config) {
  const token = decryptRoute(Vue.$cookies.get('auth_token_jwt'));
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default AxiosInstance;
